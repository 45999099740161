<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div
        class="content-main mb24px"
        style="display: flex; justify-content: space-between"
      >
        <div class="search-box">
          <span v-if="0" style="margin-right: 15px">
            业务类型
            <el-select v-model="orderType" clearable placeholder="请选择业务类型">
              <el-option
                v-for="item in OrderTypeOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </span>
          <span v-if="0" style="margin-right: 15px">
            发货人
            <el-input
              placeholder="请输入发货人"
              v-model="queryContent"
              clearable
            ></el-input>
          </span>
          <el-date-picker
            v-model="valueDate"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
          <span style="margin-left: 15px">
            <el-input
              placeholder="发货人、发货地、目的地"
              v-model="queryContent"
              clearable
            ></el-input>
          </span>
          <el-button style="margin: 0 10px 0 20px" type="primary" @click="clickExport"
            >导出</el-button
          >
          <el-button type="success" size="mini" class="btn-success" @click="clickCheck"
            >生成对账单</el-button
          >
        </div>
        <el-button size="mini" @click="$router.go(-1)" style="height: 28px"
          >返 回</el-button
        >
      </div>
      <div class="content-main">
        <div class="mb24px">
          <span>结算客户: {{ $route.query.partnerAccountName||'- -' }}</span>
          <!-- <span class="ml24px">查询日期: {{$route.query.startTime/1|str2ymd}} - {{$route.query.endTime/1|str2ymd}}</span> -->
        </div>
        <div>
          <template>
            <el-table
              ref="table"
              :data="tableData"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="getRowClass"
              @select="handleSelectionChange"
              @select-all="selectAll"
            >
              <el-table-column fixed type="selection" width="55"> </el-table-column>
              <el-table-column fixed type="index" label="序号" width="50">
              </el-table-column>

              <el-table-column fixed label="客户名称" show-overflow-tooltip>
                <template slot-scope="scope">{{ scope.row.partnerName||'- -' }}</template>
              </el-table-column>
              <el-table-column
                fixed
                prop="orderId"
                width="140px"
                label="订单编号"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                fixed
                prop="transportTime"
                label="运输日期"
                width="120px"
                align="center"
              >
                <template slot-scope="scope">{{
                  scope.row.transportTime | str2ymd
                }}</template>
              </el-table-column>
              <el-table-column
                fixed
                prop="plateNumber"
                label="提货车号"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column fixed prop="senderName" label="发货人" show-overflow-tooltip>
                <template slot-scope="scope">{{
                  scope.row.senderName || "- -"
                }}</template>
              </el-table-column>
              <el-table-column fixed prop="sendAddressDetail" label="发货地" show-overflow-tooltip>
                <template slot-scope="scope">{{
                  scope.row.sendAddressDetail || "- -"
                }}</template>
              </el-table-column>
              <el-table-column fixed prop="receiveAddressDetail" label="目的地" show-overflow-tooltip>
                <template slot-scope="scope">{{
                  scope.row.receiveAddressDetail || "- -"
                }}</template>
              </el-table-column>
              <el-table-column
                fixed
                prop="orderType"
                label="业务类型"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="categoryWeight"
                label="重量"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="earningUnitPrice"
                label="运费单价"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="earningFee"
                label="运费"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="carpoolingFee"
                label="拼车费"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="liftingFee"
                label="吊装费"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="weightFee"
                label="过磅费"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="unloadFee"
                label="卸车费"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="reciveable"
                label="应收金额"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="orderRemark"
                label="订单备注"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column label="操作" width="150px" align="center">
                <template slot-scope="scope">
                  <span class="table-view" @click="editOrder(scope.row)">修改订单</span>
                  <span class="table-view" @click="goAccounting(scope.row)"
                    >订单详情</span
                  >
                </template>
              </el-table-column>
            </el-table>
          </template>
          <div style="display: flex; justify-content: flex-end; margin-top: 12px">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="10"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>

        <!--<el-button @click="$router.go(-1)">返 回</el-button>-->
        <div>
          选中个
          <span class="check-style">{{ selectionOrderNum }}</span> 订单，运费共
          <span class="check-style">{{ selectionOrderEarningFee }}</span> 元，代垫费共
          <span class="check-style">{{ selectionOrderAdvanceFee }}</span> 元，应收共
          <span class="check-style">{{ selectionOrderReciveable }}</span> 元
        </div>
      </div>
      <!--对账-->
      <el-dialog title="对账" :visible.sync="dialogVisible" width="450px" center>
        <div><span class="title-font">对账信息</span></div>
        <br />
        <div style="margin-right: 90px">
          <div class="details-form-item" style="text-align: left; margin-left: 70px">
            <span>结算客户：</span>
            <span>{{ $route.query.partnerAccountName }}</span>
          </div>
          <div class="details-form-item" style="text-align: left; margin-left: 70px">
            <span>应收金额：</span>
            <span>{{ sum }}</span>
          </div>
          <div><span class="title-font">对账调整</span></div>
          <br />
          <el-form
            :model="form"
            :rules="rules"
            ref="form"
            :inline="true"
            :label-position="$config.labelPosition"
            :label-width="$config.labelWidth"
          >
            <el-form-item
              label="对账单名称"
              prop="accountCheckingName"
              style="text-align: right"
            >
              <el-input v-model="form.accountCheckingName" maxlength="12"></el-input>
            </el-form-item>
            <el-form-item
              label="确认金额"
              prop="accountCheckingAmount"
              style="text-align: right"
            >
              <el-input v-model="form.accountCheckingAmount" type="number"></el-input>
            </el-form-item>
            <el-form-item label="备注" style="text-align: right">
              <el-input v-model="form.accountCheckingRemark" maxlength="64"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <!-- <el-button type="primary" @click="clickSubmit" class="mr24px" :loading="btnLoading">生成对账单</el-button> -->
          <el-button @click="dialogVisible = false">返回</el-button>
        </span>
      </el-dialog>

      <!--导出-->
      <el-dialog title="选择导出的数据" :visible.sync="dialogExport" width="900px" center>
        <el-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
          >全选</el-checkbox
        >
        <el-checkbox-group v-model="checkIndex" @change="handleCheckedCitiesChange">
          <el-checkbox :label="item.index" v-for="(item, idx) in checkList" :key="idx">{{
            item.value
          }}</el-checkbox>
        </el-checkbox-group>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            @click="exportExcel"
            class="mr24px"
            :loading="btnLoading"
            >确 定</el-button
          >
          <el-button @click="dialogExport = false">取 消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import mixins from "@utils/mixins";
export default {
  mixins: [mixins.$exportCheckAll],
  data() {
    const vm = this;
    return {
      nav: [
        { name: "会计对账" },
        { name: "客户对账", path: "/accounting/customer-reconciliation" },
        { name: "订单明细" },
      ],

      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      valueDate: "",

      currentPage: 1,
      total: 0,
      tableData: [],
      size: 10,
      page: 1,
      isSelection: [],

      OrderTypeOption: vm.$enums.OrderType.list,
      orderType: null,
      queryContent: null,

      dialogVisible: false,
      dialogExport: false,
      form: {
        accountCheckingName: null,
        accountCheckingAmount: null,
        accountCheckingRemark: null,
      },
      rules: {
        accountCheckingName: [
          {
            required: true,
            message: "请输入对账名称",
            trigger: "blur",
          },
          { validator: this.$validator.isContainBlank },
        ],
        accountCheckingAmount: [
          { required: true, message: "请输入调整金额", trigger: "blur" },
          { validator: this.$validator.isTwoDecimal },
        ],
      },
      checkOrder: [],
      sum: 0,
      startTime: null,
      endTime: null,
      btnLoading: false,
      checkIndex: [],
      checkList: [],
    };
  },
  watch: {
    valueDate: function(nVal, oVal) {
      console.log(this.valueDate);
      // this.getDate();
        this.isSelection = [];
        this.checkOrder = [];
      this.getDate();
    },
    orderType: function (nVal, oVal) {
      this.currentPage = 1;
      this.page = 1;
        this.isSelection = [];
        this.checkOrder = [];
      this.getDate();
    },
    queryContent: function (nVal, oVal) {
      this.currentPage = 1;
      this.page = 1;
        this.isSelection = [];
        this.checkOrder = [];
      this.getDate();
    },

    dialogVisible: function () {
      if (!this.dialogVisible) {
        this.sum = 0;
      }
    },
  },
  computed: {
    // 订单数量
    selectionOrderNum() {
      return this.checkOrder.length;
    },
    // 运费
    selectionOrderEarningFee() {
      let fee = 0;
      this.checkOrder.forEach((e) => {
        fee += e.earningFee;
      });
      fee = parseFloat(fee).toFixed(3);
      fee = fee.substring(0, fee.length - 1);
      return fee;
    },
    // 代垫费
    selectionOrderAdvanceFee() {
      let fee = 0;
      this.checkOrder.forEach((e) => {
        fee += e.unloadFee + e.liftingFee + e.weightFee;
      });
      fee = parseFloat(fee).toFixed(3);
      fee = fee.substring(0, fee.length - 1);
      return fee;
    },
    // 应收
    selectionOrderReciveable() {
      let fee = 0;
      this.checkOrder.forEach((e) => {
        fee += e.reciveable;
      });
      fee = parseFloat(fee).toFixed(3);
      fee = fee.substring(0, fee.length - 1);
      return fee;
    },
  },
  methods: {
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.table.toggleRowSelection(row, true);
        });
      } else {
        this.$refs.table.clearSelection();
      }
    },

    // 勾选
    handleSelectionChange(val) {
      // 当前页选中的
      let arr = val.map((item) => item.orderId);
      // 添加选中的
      let arrObj = val.filter(
        (item) => !new Set(this.checkOrder.map((item) => item.orderId)).has(item.orderId)
      );
      this.checkOrder = [...new Set([...this.checkOrder, ...arrObj])];

      this.isSelection = [...new Set([...this.isSelection, ...arr])];
      // 当前页没选中的
      let arr1 = this.tableData
        .filter((item) => !new Set(arr).has(item.orderId))
        .map((item) => item.orderId);

      // 清除当前没有选中的
      this.checkOrder = this.checkOrder.filter(
        (item) => !new Set(arr1).has(item.orderId)
      );
      this.isSelection = this.isSelection.filter((item) => !new Set(arr1).has(item));
    },
    
    // 获取数据
    async getDate() {
      let params = {
        currentPage: this.page,
        pageSize: this.size,
        type: 0,
        orderStatus: null,
        startTime: this.valueDate?this.valueDate[0]:null,
        endTime: this.valueDate?this.valueDate[1]:null,
        orderType: this.orderType,
        queryId: this.$route.query.partnerAccountId,
        queryContent: this.queryContent,
      };
      this.$api.ListOrderAtAccountChecking(params).then((res) => {
        this.tableData = res.data;
        this.total = res.totalNum;

        this.$nextTick(() => {
          let n = this.tableData.filter((item) => {
            return new Set(this.isSelection).has(item.orderId);
          });
          this.toggleSelection(n);
        });
      });
    },
    handleSizeChange(val) {
      this.size = val;
      this.currentPage = 1;
      this.page = 1;
      this.getDate();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getDate();
    },

    selectAll(rows) {
      if (rows.length == 0) {
        this.isSelection = [];
        this.checkOrder = [];
      } else {
        let params = {
          // currentPage: this.page,
          // pageSize: this.size,
          type: 0,
          orderStatus: null,
          startTime: this.valueDate?this.valueDate[0]:null,
          endTime: this.valueDate?this.valueDate[1]:null,
          orderType: "",
          queryId: this.$route.query.partnerAccountId,
          queryContent: this.queryContent,
        };
        this.$api.ListOrderAtAccountChecking(params).then((res) => {
          this.checkOrder = res.data;
          this.isSelection = res.data.map((item) => item.orderId);
        });
      }
    },

    // 导出
    async exportExcel() {
      this.btnLoading = true;
      try {
        const url = await this.$api.Excel({
          excelKey: "SteelPartnerAccount",
          indexList: this.checkIndex,
          params: {
            type: 0,
            orderStatus: null,
            startTime: this.valueDate?this.valueDate[0]:null,
            endTime: this.valueDate?this.valueDate[1]:null,
            orderType: this.orderType,
            queryId: this.$route.query.partnerAccountId,
            queryContent: this.queryContent,
            orderIds: this.checkOrder.map((e) => e.orderId),
          },
        });
        window.open(url);
        this.dialogExport = false;
        this.isSelection = [];
        this.checkOrder = [];
        this.getDate();
      } catch (e) {
        // console.log(e)
      }
      this.btnLoading = false;
    },
    // 生成对账单
    async clickCheck() {
      if (this.checkOrder.length === 0)
        return this.$message.warning("请选择要生成对账的订单");
      this.btnLoading = true;
      try {
        await this.$api.InsertAccountCheckingInfo({
          steelAccountCheckingInfoVO: {
            accountCheckingObjectId: this.$route.query.partnerAccountId,
            orderCount: this.checkOrder.length,
            orderIdList: this.checkOrder.map((e) => e.orderId),
            // reciveableAmount: this.sum,
            // accountCheckingName: this.form.accountCheckingName,
            // accountCheckingAmount: this.form.accountCheckingAmount,
            // accountCheckingRemark: this.form.accountCheckingRemark,
          },
        });
        this.$notify({
          title: "成功",
          message: `操作成功`,
          type: "success",
        });
        // this.dialogVisible = false
        this.isSelection = [];
        this.checkOrder = [];
        this.getDate();
      } catch (e) {
        // console.log(e)
      }
      this.btnLoading = false;
    },

    // 导出弹窗
    clickExport() {
      if (this.checkOrder.length === 0)
        return this.$message.warning("请选择要导出的订单");
      this.dialogExport = true;
      this.$api
        .GetExcelExportKeyAndColumn({
          excelKey: "SteelPartnerAccount",
        })
        .then((res) => {
          this.checkList = res.chsList;
          this.checkIndex = this.checkList.map((e) => {
            return e.index;
          });
        });
    },
    // clickCheck () {
    //   if (this.checkOrder.length === 0) return this.$message.warning('请选择要对账的订单')
    //   this.dialogVisible = true
    //   const arr = this.checkOrder.map(e => e.reciveable)
    //   arr.forEach(e => {
    //     this.sum += Number(e)
    //   })
    // },
    selectionChange(e) {
      console.log(e);
      this.checkOrder = e;
    },
    goAccounting(e) {
      this.$router.push({
        path: "/accounting/customer-accounting",
        query: {
          orderId: e.orderId,
          page: "对账",
          activePath: `/accounting/customer-reconciliation`,
        },
      });
    },
    editOrder(e) {
      this.$router.push({
        path: "/accounting/orde-eaitDetailed",
        query: {
          orderId: e.orderId,
          page: "对账",
          activePath: `/accounting/customer-reconciliation`,
        },
      });
    },
    getRowClass({ rowIndex }) {
      if (rowIndex === 0) {
        return "background:#FAFAFA;color:#262626;font-weight:600;";
      } else {
        return "";
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    // to.meta.keepAlive = true
    next();
  },
  async mounted() {
    this.getDate();
  },
  async activated() {},
  // deactivated () {
  //   console.log('出去')
  // }
};
</script>

<style lang="sass" scoped>
.content-box
  .title-font
    margin-left: 60px
    font-size: 16px
    font-weight: 400
    color: rgba(51, 51, 51, 1)
    line-height: 23px

  .search-box
    display: flex;
    align-items: center;

  .details-form-item
    width: 100% !important

  /deep/ .el-form-item
    width: 100% !important

  /deep/ .el-checkbox
    min-width: 92px

  .check-style
    color: $-color-primary-2
  /deep/ .el-input
    width: auto;
</style>
